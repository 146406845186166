.stateBanner {
  background-color: #eee;
}
.stateBannerField {
  display: flex;
  flex-direction: row;
  float: left;
  width: 220px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 40px;
  gap: 20px;
}

.buttonBox {
  width: 350px;
}

.cheat {
  display: flex;
  flexDirection: row;
  align-items: center;
  margin-bottom: 8px;
}

.cheat img {
  width: 18px;
}